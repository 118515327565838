import type { AppProps } from 'next/app'
import Head from 'next/head'
import '@styles/settings.scss'

function CustomApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Jungle Network // Engaging tech talent for trustworthy companies</title>
      </Head>
      <main className="app">
        <Component {...pageProps} />
      </main>
    </>
  )
}

export default CustomApp
