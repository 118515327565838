import * as Sentry from '@sentry/nextjs'

import { environment } from './config/environment'

if (environment.SENTRY_DSN) {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    environment: environment.SENTRY_ENV,
    release: environment.SENTRY_RELEASE,
  })
}
export {}
