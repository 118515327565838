export const environment = {
  SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  SENTRY_ENV: process.env.NEXT_PUBLIC_SENTRY_ENV,
  SENTRY_RELEASE: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
  HOTJAR_ID: process.env.NEXT_PUBLIC_HOTJAR_ID,
  FACEBOOK_PIXEL_ID: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
  AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  DETECT_LANGUAGE_API_KEY: process.env.NEXT_PUBLIC_DETECT_LANGUAGE_KEY,
}
